import { format } from 'date-fns'
import { useField } from 'react-final-form'
import { dateStringToObject, hourFormattedByNumber, parseDayMonthYear } from '../../../utils/helpers'

export const publishType = {
  publish: 'publish',
  unpublish: 'unpublish'
}

const today = format(new Date(), 'dd/MM/yyyy')
const minHourToday = () => new Date().getHours() + 1

const useSchedule = () => {
  const { input: publishInput } = useField(`${publishType.publish}.date`)
  const { input: unpublishInput } = useField(`${publishType.unpublish}.date`)
  const { input: publishHourInput } = useField(`${publishType.publish}.hour`)
  const { input: unpublishHourInput } = useField(`${publishType.unpublish}.hour`)

  const publishHourValue = publishHourInput.value?.split(':')[0]
  const unpublishHourValue = unpublishHourInput.value?.split(':')[0]

  const minStartHour = today === publishInput.value || publishInput.value === '' ? minHourToday() : 0
  let minEndHour = 0
  if (unpublishInput.value === publishInput.value && publishHourValue) {
    minEndHour = parseInt(publishHourValue) + 1
  } else if (today === unpublishInput.value) {
    minEndHour = minHourToday()
  }

  const minStartDate = dateStringToObject(today)
  const minEndDate = dateStringToObject(publishInput.value || today)

  const handleResetUnpublishHour = publishHour => {
    if (publishInput.value === unpublishInput.value && publishHour > parseInt(unpublishHourValue)) {
      unpublishHourInput.onChange(undefined)
    }
  }

  const handleResetUnpublishDateTime = dateValue => {
    const publishDate = parseDayMonthYear(dateValue)
    const unpublishDate = parseDayMonthYear(unpublishInput.value)
    if (publishDate > unpublishDate) {
      unpublishInput.onChange(undefined)
      unpublishHourInput.onChange(undefined)
    }
    if (dateValue === unpublishInput.value && parseInt(publishHourValue) > parseInt(unpublishHourValue)) {
      unpublishHourInput.onChange(undefined)
    }
  }

  const handlePublishCheckChange = value => {
    if (value) {
      publishInput.onChange(today)
      publishHourInput.onChange(hourFormattedByNumber(minStartHour))
    } else {
      publishInput.onChange(null)
      publishHourInput.onChange(null)
    }
  }

  const handleUnpublishCheckChange = () => {
    unpublishInput.onChange(undefined)
    unpublishHourInput.onChange(undefined)
  }

  return {
    minStartHour,
    minEndHour,
    minStartDate,
    minEndDate,
    handleResetUnpublishHour,
    handleResetUnpublishDateTime,
    handlePublishCheckChange,
    handleUnpublishCheckChange
  }
}

export default useSchedule
