import React from 'react'
import PublishScheduleField from './PublishScheduleField'
import useSchedule, { publishType } from '../../../hooks/feature/general/useSchedule'

const PublishScheduleRangeField = () => {
  const {
    minEndHour,
    minStartHour,
    minStartDate,
    minEndDate,
    handleResetUnpublishDateTime,
    handleResetUnpublishHour,
    handlePublishCheckChange,
    handleUnpublishCheckChange
  } = useSchedule()

  return (
    <div className="ml-7">
      <PublishScheduleField
        kind={publishType.publish}
        minimumDate={minStartDate}
        minHour={minStartHour}
        datePlaceholder="Desde"
        notifyComplete
        onHourChange={handleResetUnpublishHour}
        onDateChange={handleResetUnpublishDateTime}
        onCheckChange={handlePublishCheckChange}
      />
      <PublishScheduleField
        kind={publishType.unpublish}
        minimumDate={minEndDate}
        minHour={minEndHour}
        datePlaceholder="Hasta"
        onCheckChange={handleUnpublishCheckChange}
      />
    </div>
  )
}

export default PublishScheduleRangeField

PublishScheduleRangeField.propTypes = {}
